
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        







































































































.home-organizers-hero {
  position: relative;

  @include fluid(
    padding-top,
    (
      xs: 60px,
      xxl: 200px,
    )
  );

  @include fluid(
    padding-bottom,
    (
      xs: 40px,
      xxl: 480px,
    )
  );

  .personality & {
    @include fluid(
      padding-bottom,
      (
        m: 0,
        xxl: 480px,
      )
    );

    padding-top: 0;
  }

  @include mq(l) {
    .personality & {
      display: flex;
      align-items: center;
      min-height: 73rem;
      padding: $spacing * 8 0 $spacing * 5;
    }
  }
}

.content {
  @include mq($until: l) {
    transform: translateY(-20%);

    .personality & {
      transform: none;
    }
  }
}

.title,
.text,
.button {
  @include mq(l) {
    margin-left: col(1);
  }
}
.text {
  max-width: 50rem;
  margin-top: $spacing * 1.5;
  line-height: 1.66;

  @include fluid(
    font-size,
    (
      xxs: 13px,
      xxl: 15px,
    )
  );
}
.button {
  @extend %button-nostyle;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: $spacing * 2;
  padding: $spacing * 0.5 $spacing;
  color: $c-white;
  background-color: $c-mustard;
  border-radius: 5rem;
  text-align: center;
}

.button__icon {
  display: block;
  transition: transform 0.2s ease-out;

  .icon {
    width: 3rem;
    height: 3rem;
  }

  .button:hover & {
    transform: scale(1.2);
  }
}

.button__label {
  @extend %ff-alt;

  line-height: 1;

  @include fluid(
    font-size,
    (
      xxs: 14px,
      xxl: 18px,
    )
  );
}

.picture {
  position: relative;
  z-index: -1;
  max-width: $content-width;
  margin: 0 auto;

  ::v-deep .picture__container {
    overflow: visible;
    width: 100%;
  }

  @include mq(s) {
    ::v-deep .picture__container {
      width: 80%;
      margin-left: auto;
    }
  }

  @include mq($until: l) {
    height: 36rem;
    min-height: 36rem;
  }

  @include mq(l) {
    @include get-all-space;

    .personality & {
      left: initial;
      width: 70%;
      height: 120%;

      img {
        @include image-fit(contain);
      }
    }
  }
}
