
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


























































































































































































/* stylelint-disable no-descending-specificity */
.home-organizers-card-episodes {
  position: relative;
  overflow: hidden;

  .home-organizers & {
    margin-top: -15%;
  }
}

.home-organizers-card-episodes__title {
  ::v-deep em {
    color: $c-dark-grey;
  }
}

.home-organizers-card-episodes__intro {
  @include mq(l) {
    padding-right: col(3, 10);
  }
}

.content-outer {
  @include mq($until: l) {
    /* stylelint-disable-next-line declaration-no-important */
    padding: 0 !important;
  }
}

.content {
  position: relative;
}

.slider {
  width: 85vw;
  margin-top: $spacing * 2.5;

  &:focus:not(:focus-visible) {
    outline: none;
  }

  @include mq($until: l) {
    margin-right: -2rem;
    margin-left: -2rem;
  }

  @include mq(l) {
    width: 75vw;
    margin-bottom: 20rem;
  }

  @include mq(xl) {
    width: 60vw;
    max-width: 86rem;
  }
}

.video {
  ::v-deep {
    .is-dragging & {
      .button {
        pointer-events: none;
      }
    }
  }

  & + & {
    margin-left: $spacing;

    @include mq(l) {
      margin-left: $spacing * 5;
    }
  }
}

.buttons {
  @include fluid(
    margin-bottom,
    (
      xxs: 35px,
      m: 50px,
    )
  );

  display: flex;

  @include mq(l) {
    position: absolute;
    top: 50%;
    left: -7%;
    display: flex;
    justify-content: space-between;
    width: 105%;
    margin-top: 5rem;
    transform: translate(0%, -100%);
  }

  @include mq(xl) {
    width: 101.5%;
  }

  @include mq(wrapper) {
    left: -8%;
    width: 105.5%;
  }
}

.button {
  /* stylelint-disable-next-line declaration-no-important */
  padding: 1rem !important;

  &:disabled {
    opacity: 0.5;
  }

  & + & {
    margin-left: math.div($spacing, 2);
  }
}

::v-deep .flickity-viewport {
  overflow: visible;
}
