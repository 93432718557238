
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



































































































































::v-deep {
  .picture__container {
    // height: 0;
    // padding-bottom: 50%;
  }

  .picture__caption {
    display: none;
  }
}

.episode {
  position: relative;

  &:focus:not(:focus-visible) {
    outline: none;
  }
}

.status-outer {
  @include get-all-space;

  overflow: hidden;
  border-radius: 8px;
}

.status {
  @extend %text-uppercase;

  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  padding: $spacing * 4 $spacing * 2.5 $spacing * 0.5;
  color: $c-white;
  font-size: 1.5rem;
  font-weight: 700;
  transform: rotate(-45deg) translate(-9%, -70%);
  background-color: $c-mustard;

  .is-soon & {
    color: $c-gray-medium;
    background-color: $c-white;
    transform: rotate(-45deg) translate(-11%, -70%);
  }
}

.loop,
.picture {
  @include fluid(
    height,
    (
      xxs: 200px,
      xxl: 600px,
    )
  );

  position: relative;
  overflow: hidden;
  width: 85vw;
  border-radius: 8px;
  box-shadow: 0 2rem 8rem rgba($c-black, 0.1);

  video {
    @include image-fit;
  }

  &::after {
    @include get-all-space;

    content: '';
    display: block;
    background: linear-gradient(
      236.31deg,
      rgba($c-black, 0) 37.49%,
      $c-white 76.34%
    );
    border-radius: 8px;
    transition: opacity 0.5s ease-out;

    .is-selected & {
      opacity: 0;
    }
  }

  @include mq(l) {
    width: 75vw;
  }

  @include mq(xl) {
    width: 60vw;
    max-width: 86rem;
  }
}

.content {
  @include fluid(
    max-width,
    (
      s: 380px,
      l: 560px,
    )
  );

  z-index: 2;
  margin: 0 $spacing;
  padding: $spacing;
  border-radius: 8px;
  transform: translateY(-15rem);
  transition: 0.5s ease-out;
  transition-property: background-color, transform, box-shadow;

  ::v-deep {
    .g-intro {
      max-width: 100%;
    }
  }

  .is-selected & {
    background-color: $c-white;
    transform: translateY(-5rem);
    box-shadow: 0 2rem 6rem rgba($c-black, 0.15);
  }

  @include mq(m) {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0 $spacing * 2;
    padding: $spacing * 2 $spacing * 2 $spacing * 1.5 $spacing * 2;
    transform: translateY(0);

    .is-selected & {
      transform: translateY(30%);
    }
  }
}

.headline {
  color: $c-gray-medium;
}

.title {
  margin-top: math.div($spacing, 2);
}

.text {
  margin-top: math.div($spacing, 2);
}

.icon {
  width: 3rem;
  height: 3rem;
  margin-top: $spacing;
  fill: $c-mustard;
  transition: transform 0.3s ease-out;

  .content:hover & {
    transform: translateX(2rem);
  }
}

.button {
  @include get-all-space;

  /* stylelint-disable-next-line declaration-no-important */
  width: 100% !important;
  opacity: 0;
}
