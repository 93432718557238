
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

























































.home-organizers-cta {
  position: relative;
  overflow: hidden;

  .personality & {
    display: flex;
    flex-direction: column;
  }

  @include mq(m) {
    display: flex;
    // align-items: center;
  }
}

.content {
  position: relative;

  @include fluid(
    padding-top,
    (
      xxs: 0,
      xxl: 200px,
    )
  );
  @include fluid(
    padding-bottom,
    (
      xxs: 60px,
      xxl: 200px,
    )
  );

  flex-shrink: 0;

  .personality & {
    @include fluid(
      padding-top,
      (
        xxs: 20px,
        xxl: 200px,
      )
    );
    @include fluid(
      padding-bottom,
      (
        xxs: 20px,
        xxl: 200px,
      )
    );

    transform: none;

    .home-organizers-cta:not(.alternate) & {
      margin-left: col(6);
    }

    &::before,
    &::after {
      content: none;
    }
  }

  @include mq($until: m) {
    transform: translateY(-20%);

    &::before {
      @include get-all-space;

      content: '';
      z-index: -1;
      display: block;
      background: linear-gradient(
        180deg,
        rgba($c-white, 0) 0%,
        rgba($c-white, 1) 20%
      );
    }
  }

  @include mq(m) {
    flex-basis: col(5);
    margin-left: col(1);

    .personality & {
      margin-left: col(7);
    }

    .personality .alternate & {
      margin-left: col(1.5);
    }
  }
}

.headline {
  color: $c-gray-medium;
}

.title {
  margin-top: math.div($spacing, 2);
}

.button {
  margin-top: $spacing * 2;
}

.picture {
  position: relative;
  z-index: -1;
  overflow: hidden;

  ::v-deep {
    .picture__container {
      height: 100%;
    }
  }

  &::after {
    @include get-all-space;

    content: '';
    display: block;
    background: linear-gradient(
      90deg,
      rgba($c-white, 0) 70%,
      rgba($c-white, 1) 100%
    );
  }

  &::before {
    @include get-all-space;

    content: '';
    z-index: 1;
    display: block;
    background: linear-gradient(
      270deg,
      rgba($c-white, 0) 80%,
      rgba($c-white, 1) 100%
    );
  }

  .personality & {
    width: 120%;
    margin-right: -10%;
    margin-left: -10%;

    &::before,
    &::after {
      content: none;
    }
  }

  @include mq($until: m) {
    width: 100%;
  }

  @include mq(m) {
    flex: 1;
    max-height: 58rem;

    ::v-deep {
      img {
        @include image-fit(contain, bottom);
      }
    }

    &::after {
      @include get-all-space;

      content: '';
      display: block;
      background: linear-gradient(
        90deg,
        rgba($c-white, 0) 70%,
        rgba($c-white, 1) 100%
      );
    }

    &::before {
      @include get-all-space;

      content: '';
      z-index: 1;
      display: block;
      background: linear-gradient(
        270deg,
        rgba($c-white, 0) 80%,
        rgba($c-white, 1) 100%
      );
    }

    .personality & {
      @include get-all-space;

      ::v-deep {
        .picture__container {
          height: 100%;
        }

        img {
          @include image-fit(cover);
        }
      }
    }
  }
}
